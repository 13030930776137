import React from "react";
import Page from "../../components/Page";
import Heading from "../../components/Heading";

import styleData from "./styles";
import StyleProvider from "../../functions/StyleProvider";

const styleProvider = new StyleProvider(styleData);

export default class About extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_about"}>
                <Heading value={"About"} />
                <section style={style.container}>
                    <span style={style.text}>
If the music of still sane was a garden, everything would be allowed to grow. It would probably be a familiar place where a whole life would fit in. Everything is quite overgrown but still so quiet that you can hear the plants grow.<br />
The silence is only interrupted by people who bring chaos and warmth to this place. <br /><br />

"Feeling a lot and being surrounded by good people is all I want," says Michel. And you can feel that. Michel Stirner, the Stuttgart-based indie pop newcomer behind the project still sane, does just that. He puts his sensitivity into songs, writes about being lost, about confrontation and development, and above all about one thing: the desire to find out who you are and what you really need. Doing a lot, always being somewhere in between, allowing closeness and needing distance at the same time, home, the need to be alone and yet always wanting to see your dearest friends. Everything, always, all at the same time. <br /><br />

It is precisely at these moments that still sane returns to this garden and creates a sound somewhere between indie pop and organic electronic soundscapes in which you can rest and lose yourself and eventually find yourself in between. The new EP "i thought that i'd know" was released on the 17th of November.<br />
                    </span>
                </section>
            </Page>
        );
    }

}

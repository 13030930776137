import React from "react";

import Page from "../../components/Page"
import SocialIcon from "../../components/home/SocialIcon";
import Button from "../../components/Button";

import StyleProvider from "../../functions/StyleProvider";
import styleData from "./styles";
import DesktopNavigation from "../../components/navigation/DesktopNavigation";
import Config from "../../functions/Config";
import ArrowDown from "../../components/ArrowDown";
import Navigator from "../../functions/Navigator";

const styleProvider = new StyleProvider(styleData);

export default class Home extends React.Component {

    constructor(props){
        super(props);

        this.state = {

        };
    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_home"}>
                <section style={style.container}>
                    { Config.isDesktop() ? <DesktopNavigation /> : null}
                    <span style={{...style.text, ...style.single_title}}>{"SLEEPLESS"}</span>
                    <span style={style.text}>
                        THE NEW SINGLE<br />
                        OUT NOW
                    </span>
                    <Button style={style.button} value={"LISTEN NOW"} href={"https://stillsane.ffm.to/sleepless"} />
                    <div style={style.iconContainer}>
                        <SocialIcon style={style.icon} name={"spotify"} />
                        <SocialIcon style={style.icon} name={"email"} onClick={(e) => {
                            e.preventDefault();
                            Navigator.navigate("app://page/newsletter");
                        }} />
                        <SocialIcon style={style.icon} name={"instagram"} />
                        <SocialIcon style={style.icon} name={"youtube"} />
                    </div>
                </section>
                <ArrowDown />
            </Page>
        );
    }

}
